import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Юрист по недвижимости | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Опытные юристы по недвижимости помогут вам в решении задач любой сложности." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/yurist-po-nedvizhimosti'
				name="Юрист по недвижимости | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Опытные юристы по недвижимости помогут вам в решении задач любой сложности."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Юрист по недвижимости" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Юрист по недвижимости"}
			
				html={

				`
				<p class="TextText">
                	<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Что такое сделка с недвижимостью? Есть много определений этому понятию как юридических, так и экономических, но сами участники сделки с недвижимостью на это вопрос могут ответить очень просто – это большие риски потерять много денег, причём одномоментно.
					Я даже не буду говорить про мошенников в сфере недвижимости, для которых обман – это их профессиональная деятельность и чтобы вовремя распознать их намерения нужен другой профессионал, который будет защитить ваши интересы. Сейчас я говорю о добросовестных участниках сделки, каждый из которых преследует свою выгоду.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					При оформлении сделок с недвижимостью многие думают, что все условия, на которых они готовы совершить сделку и которые были включены в договор, обязательно защитят их интересы. Если при совершении сделки что-то пойдёт не так, можно будет пригласить юриста, который в суде, опираясь на эти пункты договора, выиграет дело. Это очень опасное заблуждение. Дело в том, что если какие-то условия договора противоречат закону, то они являются ничтожными, т.е. не действуют. При этом сам договор (за исключением этих ничтожных условий) продолжает оставаться действующим. Наверное, нет необходимости напоминать, сколько законов существует в нашем государстве. Так что не удивляйтесь, если условия (пункты) договора, на которые вы рассчитывали, на поверку окажутся недействительными.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					К сожалению, в нашей стране до сих пор существует практика, когда при оформлении сделок с недвижимостью на несколько миллионов, участники сделки, желая сэкономить несколько тысяч, не обращаются за помощью к профессионалам. Риски участников сделки, как правило, напрямую связаны её ценой, например, при покупке квартиры за 1-2 миллиона рублей, неправильное оформление задатка может обернуться его потерей – это 50-100 тысяч рублей, а при покупке нежилых зданий за 10-20 миллионов, можно остаться без энергоисточников, что повлечёт убытки в миллионы рублей. Стоимость консультации юриста по недвижимости и в целом услуги юриста по недвижимости, по сравнению с указанными суммами, просто мизерны. 
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Юридические услуги по недвижимости уже давно перестали быть «предметом роскоши». Практически каждый участник сделки с недвижимостью может позволить себе пригласить для юридической поддержки юриста по недвижимости. На сегодняшний день юридические услуги по недвижимости предлагают многие компании. Однако качество оказываемых услуг сильно зависит от практического опыта в данной сфере. Кроме того, юридическое сопровождение сделок с недвижимостью не стоит доверять риелторским компаниям. Основная их задача – это "свести" продавца с покупателем. Зачастую риелтор не имеет юридического образования и в лучшем случае только слышал о существовании Гражданского Кодекса. В нашей команде работает юрист имеющий опыт работы в более 25 лет.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Помощь юриста по недвижимости, по Вашему желанию, может быть разного объёма. Вы можете ограничиться устной консультации, на которой можно выяснить для себя сомнительные моменты или получить полное сопровождение сделки купли-продажи недвижимости, включающее в себя: проверка юридической чистоты сделки с недвижимостью  (проверку прав собственности на недвижимость, отсутствие арестов и обременений), подготовку договора купли-продажи недвижимости и его согласование с контрагентом, организацию схемы безопасного расчёта по сделке, помощь при подаче документов для регистрации перехода права собственности.
				</p>

				<p class="TextH"><b>Оказываемые услуги</b></p>

				<p class="TextH">консультации юриста по недвижимости</p>
				<p class="TextH">оформление сделок с недвижимостью</p>
				<p class="TextH">юридическое сопровождение сделок с недвижимостью</p>
				<p class="TextH">правовая экспертиза правоустанавливающих документов собственника недвижимости</p>
				<p class="TextH">подготовка договора купли-продажи недвижимости</p>
				<p class="TextH">согласование условий договора купли-продажи недвижимости с контрагентами</p>
				<p class="TextH">сопровождение сделки купли-продажи недвижимости</b></p>
				<p class="TextH">регистрация прав на недвижимость</p>
				<p class="TextH">помощь в продаже недвижимости</p>
				<p class="TextH">помощь в покупке недвижимости</p>
				<p class="TextH">помощь в оформлении недвижимости</p>
				<p class="TextH">проверка чистоты сделки с недвижимостью</p>
				<p class="TextH">прочие услуги юриста по недвижимости</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
